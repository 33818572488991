<template>
    <div class="action-schedule-complete">
        <div class="title f-bold" v-html="`약속이 확정되었어요`" />
        <div
            v-if="content.self_reservation === 1"
            class="warning f-bold m-t-8"
            v-html="`회원님이 장소를 직접 예약합니다.`"
        />
        <ScheduleProfile
            :user="getUser(content)"
            :date="content.date_confirmed"
            :place="content.meet_place"
            :food="foodPrefer"
            :meetType="meetType"
            class="meeting-info"
        />
        <button
            v-if="content.self_reservation === 0 && chat.user.gender === 0"
            class="btn btn-black btn-block"
            @click="openModalMeetingPlaceInput"
        >
            장소 입력하기
        </button>
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'ActionScheduleComplete',
    props: ['message'],
    components: {},
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        getUser() {
            return person => {
                return {
                    photo: person.urls[0],
                    name: person.name,
                    birthday: person.birthday,
                }
            }
        },
        foodPrefer() {
            if (this.content.foods_unpreferred_status === 0) {
                return '다 잘먹어요'
            } else {
                return this.content.foods_unpreferred
            }
        },
        meetType() {
            const content = [
                {
                    id: 1,
                    name: 'meal',
                    title: '식사',
                    selected: false,
                },
                {
                    id: 2,
                    name: 'coffee',
                    title: '커피',
                    selected: false,
                },
                {
                    id: 3,
                    name: 'both',
                    title: '식사 또는 커피',
                    selected: false,
                },
            ]
            return content.find(c => c.name === this.content.meet_type_confirmed).title
        },
        chat() {
            return this.$store.getters.chat
        },
    },
    methods: {
        async openModalMeetingPlaceInput() {
            if (this.content.self_reservation) {
                this.$modal.basic({
                    body:
                        '남성 회원이 직접 예약하기로 했습니다. 약속이 임박했는데 남성 회원이 장소를 입력 안한 경우에 매니저님이 장소를 정한 후에 입력해주세요.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                return
            }
            try {
                const res = await this.$modal.custom({
                    component: 'ModalInput',
                    options: {
                        title: '장소 입력하기',
                        inputs: [
                            {
                                type: 'input',
                                key: 'map',
                                placeholder: '네이버 지도 주소를 입력해주세요.',
                            },
                        ],
                    },
                })
                if (res.length) {
                    const map = res.find(a => a.key === 'map')
                    const data = await scheduleService.addReservation({
                        url: map.text,
                        schedule_id: this.content.schedule_id,
                    })
                    // console.log('data', data)
                }
            } catch (e) {
                // console.log(e)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.action-schedule-complete {
    .title {
        padding: 0;
    }
    width: 100%;
    padding: 16px;
    .meeting-info {
        width: 100%;
    }
    .warning {
        color: $pink-deep;
        font-size: 14px;
    }
}
</style>
